import React, { useEffect, useState } from "react";

import { apiCheckEmailExist, apiRegister, apiSubscribeEmail } from "../services/authService";
import { console_log, empty } from "../utils/misc";
import { showToast } from "../utils/utils";
import { BASE_APP_URL } from "../config/constants";

const BannerMoc = "/assets/images/home/mobile.png";
const COUPON_CODE = "SCOUT101"

const RegisterBanner = () => {
  useEffect(() => {

  }, []);

  const [loading, setLoading] = useState(false)

  const defaultFromData = {
    email: "",
    submit: null
  }
  const [formData, setFormData] = useState(defaultFromData)

  const checkFormValidation = (form_data, current_field_name = "") => {
    let isValid = true
    if (empty(form_data['email'])) {
      isValid = false
    }
    return isValid
  }

  const onChangeFormField = (e, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = e.target.value
    setFormData(form_data)
  }

  const getSponsorName = () => {
    let sponsorName = ""
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)
      sponsorName = searchParams.get('ref')
    }
    console.log(`sponsorName:::`, sponsorName)
    return sponsorName
  }

  const sponsor_mame = getSponsorName()

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = checkFormValidation({ ...formData })
    console_log("isValid:::", isValid)
    if (!isValid) {
      return false
    }
    onSubmitFormData(formData)
  }

  const onSubmitFormData = async (values) => {
    setLoading(true)

    const sponsorName = getSponsorName()
    const payload = {
      email: values.email,
      ref_name: sponsorName
    }
    const apiRes = await apiRegister(payload);
    console_log("apiRes::::", apiRes);
    setLoading(false)
    if (apiRes['status'] === '1') {
      if (typeof window !== 'undefined') {
        const user = apiRes['data']['user']
        window.location.href = `${BASE_APP_URL}/register?step=21&user_key=${user['encrypted_id']}`;
      }
    } else {
      const msg = apiRes['message'] ?? "Invalid request"
      showToast(msg, 'error');
    }
  }

  return (
    <section className="banner-one register-banner" id="home">
      {/* <span className="banner-one__shape-1"></span> */}
      <span className="banner-one__shape-2"></span>
      <span className="banner-one__shape-3"></span>
      <span className="banner-one__shape-4"></span>
      <div className="container">
        <div className="hide-xs">
          <div className="banner-one__moc">
            <img src={BannerMoc} alt="alter text" />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-one__content">
              <h1 className="banner-one__title">
                Take Your Store To The Next Level with our cutting-edge <br />
                <span>AI-powered Data Platform!</span>
              </h1>
              <p className="banner-one__text">
                EcomScout revolutionizes brand management on Shopify with its cutting-edge AI Data Platform.
                Seamlessly control analytics, attribution, and creative elements, all at your fingertips.
                Stay ahead of the game and drive success with unparalleled convenience and efficiency.
              </p>

              <div className="reg-form-container">
                <form className="reg-form" onSubmit={onSubmit}>
                  <div className="form-line">
                    <h5 className="text-center">Unlock clarity, starting with your email</h5>
                  </div>
                  <div className="form-line form-line-block">
                    <label htmlFor="mc-email" className="desc">Email Address *</label>
                    <input
                      name="email"
                      type="email"
                      id="mc-email"
                      className="formInput full-width"
                      placeholder="Enter your email to start now!"
                      value={formData['email']}
                      onChange={(e) => onChangeFormField(e, 'email')}
                      required={true}
                    />
                  </div>
                  <div className="form-line">
                    <p className="desc-1">
                      By Signing up, you agree to our <a className="primary" href={`${BASE_APP_URL}/terms-conditions`} target="_blank">Terms of Service</a> and <a className="primary" href={`${BASE_APP_URL}/privacy-policy`} target="_blank">Privacy Policy</a>
                    </p>
                  </div>
                  <div className="form-line">
                    <button type="submit" className="thm-btn thm-btn-hover full-width">
                      <span>Continue</span>
                    </button>
                  </div>
                  <div className="form-line">
                    <p className="desc text-center">
                      <a className="primary" href={`${BASE_APP_URL}/login`}>Already have an account?</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="show-xs">
          <div className="banner-one__moc">
            <img src={BannerMoc} alt="alter text" />
          </div>
        </div>

        <div className="show-xs">
          <div className="mt-4 text-center">
            <a href={`${BASE_APP_URL}/register`} className="banner-one__btn thm-btn">
              <span>Get Started</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RegisterBanner;
