import React from "react";
import { APP_NAME, BASE_APP_URL } from "../config/constants";
//import mocImage from "../assets/images/mocs/cta-moc-2-1.png";
// import mocImage from "../assets/images/home/dog.png";
const mocImage = "/assets/images/home/rocket.png";

const SetupGuide = (props) => {
  const { showButton = true } = props

  return (
    <section className="cta-two setup-guide">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="full-height img-wrapper-center">
              <img src={mocImage} className="img-responsive rocket-img" alt="alter text" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cta-two__content">
              <i className="cta-two__icon dimon-icon-training"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  How long does it take to get fully <span>setup</span>? (ALMOST INSTANT!)
                </h2>
              </div>
              <div className="cta-two__text">
                <p>
                  EcomScout makes it easy to get your Shopify store up and running in no time. With our simple step-by-step guide, you can be up and selling in just 10 minutes.
                  No engineers required!
                </p>
              </div>
              <div className="landing-item-list">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check"></i>Sign up for EcomScout and connect your Shopify store instantly!
                  </li>
                  <li>
                    <i className="fa fa-check"></i>No technical skills requires!
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Our support and community is ALWAYS ready to help!
                  </li>
                </ul>
              </div>

              {
                (showButton) ? (
                  <>
                    <div className="text-center-xs">
                      <a href={`${BASE_APP_URL}/register`} className="thm-btn">
                        <span>Get Started</span>
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SetupGuide;
