import React from "react";

const Features = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Checkout Our <span>Software</span> <br /> Features Below.
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-laptop"></i>
                <h3>
                  <a href="#none">
                    All in One
                  </a>
                </h3>
                <p>
                  Dive into an ocean of data, conveniently within your grasp.
                </p>
                {/* <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-presentation"></i>
                <h3>
                  <a href="#none">
                  No Matter Where You Are
                  </a>
                </h3>
                <p>
                Access a comprehensive view of your store's vital data through a customizable dashboard!
                </p>
                {/* <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-target"></i>
                <h3>
                  <a href="#none">
                  EcomScout Pixel
                  </a>
                </h3>
                <p>
                Get unparalleled insights with dynamic alerts, enabling thorough comparisons of ad spend, revenue, and ROAS across all active channels!
                </p>
                {/* <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-visualization"></i>
                <h3>
                  <a href="#none">
                  Powered By A.I.
                  </a>
                </h3>
                <p>
                Leverage the power of AI to optimize your ad campaigns and maximize their effectiveness.
                </p>
                {/* <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Features;
